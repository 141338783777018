import React from 'react';  
import './PrivacyPolicyPopup.css'; // Create and style this CSS file as needed  
  
function PrivacyPolicyPopup({ onClose }) {  
    return (  
        <div className="privacy-policy-popup">  
            <div className="popup-content">  
                <h1><strong>Privacy Policy</strong></h1>  
                <p>Welcome to InfoSecRisk.ai ("we", "our", "us"). We are committed to protecting and respecting your privacy. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our application, InfoSecRisk.ai, and any related services (collectively, the "Service").</p>  
                <h2><strong>1. Information We Collect</strong></h2>  
                <p>We may collect and process the following data about you:</p>  
                <ul>  
                    <li><strong>Personal Identification Information</strong>: Given Name, Last Name, Email Address for authentication purposes.</li>  
                    <li><strong>Documents and User Input</strong>: Any documents you upload and the information you input into the application.</li>  
                    <li><strong>Technical Data</strong>: IP address, browser type, operating system, and other technical data.</li>  
                </ul>  
                <h2><strong>2. How We Use Your Information</strong></h2>  
                <p>We use the information we collect for the following purposes:</p>  
                <ul>  
                    <li>To provide, maintain, and improve our Service.</li>  
                    <li>To identify information security risks as requested by you.</li>  
                    <li>To communicate with you, including responding to your inquiries and sending you updates and security alerts.</li>  
                    <li>To comply with legal obligations and protect our rights and interests.</li>  
                </ul>  
                <h2><strong>3. Data Processing and Storage</strong></h2>  
                <p>All data is processed within the European Union and stored on secure servers provided by Microsoft Azure, located in the Netherlands and France. Data is temporarily stored and processed using Azure services, and is encrypted in transit.</p>  
                <h2><strong>4. Data Security</strong></h2>  
                <p>We implement appropriate technical and organizational measures to protect your data against unauthorized access, alteration, disclosure, or destruction. Data is encrypted in transit to ensure security.</p>  
                <h2><strong>5. Your Data Protection Rights</strong></h2>  
                <p>You have the following rights regarding your data:</p>  
                <ul>  
                    <li><strong>Account Deletion</strong>: You have the right to request the deletion of your account and all associated personal data. To request account deletion, please contact us at contact@infosecrisk.ai.</li>  
                </ul>  
                <h2><strong>6. Changes to This Privacy Policy</strong></h2>  
                <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.</p>  
                <h2><strong>7. Contact Us</strong></h2>  
                <p>If you have any questions about this Privacy Policy, please contact us at:</p>  
                <ul>  
                    <li>InfoSecRisk.ai</li>  
                    <li>contact@infosecrisk.ai</li>  
                </ul>  
                <div className="close-button-container">  
                    <button className="close-button" onClick={onClose}>Close</button>  
                </div>  
            </div>  
        </div>  
    );  
}  
  
export default PrivacyPolicyPopup;