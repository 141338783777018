export const msalConfig = {

  auth: {

    clientId: "d0e0fcf8-2782-4c6b-8402-2f9fe2f7861b", // This is the ONLY mandatory field that you need to supply.

    authority: `https://infosecrisk.b2clogin.com/infosecrisk.onmicrosoft.com/B2C_1_SUSI`, // Choose SUSI as your default authority.

    knownAuthorities: [`infosecrisk.b2clogin.com`], // Mark your B2C tenant's domain as trusted.

    redirectUri: 'https://infosecrisk.ai/', // You must register this URI on Azure Portal/App Registration. Defaults to window.location.origin

    postLogoutRedirectUri: 'https://infosecrisk.ai/', // Indicates the page to navigate after logout.

    navigateToLoginRequestUrl: false, // If 'true', will navigate back to the original request location before processing the auth code response.

  },

  cache: {

    cacheLocation: 'localStorage', // Configures cache location. 'sessionStorage' is more secure, but 'localStorage' gives you SSO between tabs.

    storeAuthStateInCookie: true, // Set this to 'true' if you are having issues on IE11 or Edge

  }

}
