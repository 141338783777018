import React from 'react';  
import './TermsOfUsePopup.css'; // Make sure to create and style this CSS file as needed  
  
function TermsOfUsePopup({ onClose }) {  
    return (  
        <div className="terms-of-use-popup">  
            <div className="popup-content">  
                <h1><strong>Terms of Use</strong></h1>  
                <p>Welcome to InfoSecRisk.ai ("we", "our", "us"). These Terms of Use ("Terms") govern your use of our application, InfoSecRisk.ai, and any related services (collectively, the "Service"). By accessing or using our Service, you agree to comply with and be bound by these Terms.</p>  
                  
                <h2><strong>1. Acceptance of Terms</strong></h2>  
                <p>By using our Service, you agree to these Terms in their entirety. If you do not agree, please do not use our Service.</p>  
                  
                <h2><strong>2. Current App Version</strong></h2>  
                <p>The current version of the app is free. We reserve the right to change this at any time.</p>  
                  
                <h2><strong>3. Use at Your Own Risk</strong></h2>  
                <p>The content generated by this app is for informational purposes only. You use this content at your own risk. We are not responsible for any incorrect advice provided by the app. Users must use their own experience and knowledge to validate the content generated by the app.</p>  
                  
                <h2><strong>4. AI-Generated Risk Advice</strong></h2>  
                <p>The app uses artificial intelligence to generate risk advice. This advice must be validated by users before taking any action based on it.</p>  
                  
                <h2><strong>5. Service Availability</strong></h2>  
                <p>The app is currently in a proof of concept state and may not work at times. We do not guarantee uninterrupted access to the Service.</p>  
                  
                <h2><strong>6. Account Deletion</strong></h2>  
                <p>We reserve the right to delete user accounts without any advance notice. If your account is deleted, you may lose access to any data associated with your account.</p>  
                  
                <h2><strong>7. Prohibited Content</strong></h2>  
                <p>Users are prohibited from adding any harmful content to the app. Harmful content includes, but is not limited to, viruses, malware, and any content that infringes on the rights of others.</p>  
                  
                <h2><strong>8. Changes to Terms</strong></h2>  
                <p>We may update these Terms from time to time. We will notify you of any changes by posting the new Terms on this page. Your continued use of the Service following the posting of revised Terms means that you accept and agree to the changes.</p>  
                  
                <h2><strong>9. Contact Us</strong></h2>  
                <p>If you have any questions about these Terms, please contact us at:</p>  
                <ul>  
                    <li>InfoSecRisk.ai</li>  
                    <li>contact@infosecrisk.ai</li>  
                </ul>  
                  
                <div className="close-button-container">  
                    <button className="close-button" onClick={onClose}>Close</button>  
                </div>  
            </div>  
        </div>  
    );  
}  
  
export default TermsOfUsePopup;