import { useState, useRef, useEffect, useImperativeHandle, forwardRef } from "react";  
import axios from 'axios';  
import UploadModal from './UploadModal';  
  
const ChatInput = forwardRef(({ userIdmsal, onNewMessage, onFileUplaod, initalQuery, initialLoading, queryData, isUploadedFile, onClearFile, onChatInputText, context, setContext, onClearChat }, ref) => {  
    const [file, setFile] = useState(null);  
    const [uploadStatus, setUploadStatus] = useState('');  
    const [isUploading, setIsUploading] = useState(false);  
    const [isUploaded, setIsUploaded] = useState(false);  
    const [loading, setLoading] = useState(false);  
    const [query, setQuery] = useState('');  
    const fileInputRef = useRef(null);  
  
    useEffect(() => {  
        if (initalQuery) {  
            handleSend(initalQuery);  
        }  
    }, [initalQuery]);  
  
    useEffect(() => {  
        if (query) {  
            queryData(query);  
        }  
    }, [query, queryData]);  
  
    useEffect(() => {  
        initialLoading(loading);  
    }, [loading, initialLoading]);  
  
    const handleFileSelection = () => {  
        fileInputRef.current?.click();  
    };  
  
    const handleSend = async (customQuery = null) => {  
        const currentQuery = customQuery || query.trim();  
        if (!currentQuery) {  
            return;  
        }  
  
        setLoading(true);  
        if (!customQuery) {  
            setQuery('');  
        }  
  
        try {  
            const requestBody = {  
                query: currentQuery,  
                user_id: userIdmsal,  
                context: context, // Pass the context  
            };  
            if (file) {  
                requestBody.filename = file.name;  
            }  
            const response = await axios.post('/rag', requestBody);  
            const newContext = response.data.context;  
            setContext(newContext); // Update the context  
            onNewMessage(currentQuery, response.data.answer, file ? file.name : null);  
        } catch (error) {  
            onNewMessage(currentQuery, 'Error: Could not get a response.', file ? file.name : null);  
        } finally {  
            setLoading(false);  
        }  
    };  
  
    const handleSendWithFile = async (customQuery, filename) => {  
        const currentQuery = customQuery.trim();  
        if (!currentQuery) {  
            return;  
        }  
  
        setLoading(true);  
  
        try {  
            const requestBody = {  
                query: currentQuery,  
                user_id: userIdmsal,  
                filename: filename,  
                context: context, // Pass the context  
            };  
            const response = await axios.post('/rag', requestBody);  
            const newContext = response.data.context;  
            setContext(newContext); // Update the context  
            onNewMessage(currentQuery, response.data.answer, filename);  
        } catch (error) {  
            onNewMessage(currentQuery, 'Error: Could not get a response.', filename);  
        } finally {  
            setLoading(false);  
        }  
    };  
  
    const handleFileChange = (e) => {  
        const selectedFile = e.target.files?.[0];  
        if (selectedFile) {  
            setFile(selectedFile);  
            handleFileUpload(selectedFile);  
            onFileUplaod(selectedFile.name);  
        }  
    };  
  
    const handleFileUpload = async (selectedFile) => {  
        if (!selectedFile || !userIdmsal) return;  
  
        const formData = new FormData();  
        formData.append('file', selectedFile);  
        formData.append('user_id', userIdmsal);  
  
        try {  
            setIsUploading(true);  
            setUploadStatus('Uploading...');  
            const response = await axios.post('/upload', formData, {  
                headers: { 'Content-Type': 'multipart/form-data' },  
            });  
            setUploadStatus(`Upload successful: ${response.data.filename}`);  
            setIsUploaded(true);  
            onFileUplaod(response.data.filename);  
            isUploadedFile(true);  
        } catch (error) {  
            setUploadStatus('Error: Could not upload the file.');  
            setIsUploaded(false);  
            isUploadedFile(false);  
        } finally {  
            setIsUploading(false);  
        }  
    };  
  
    const handleClearChat = () => {  
        setFile(null);  
        setQuery('');  
        setContext(''); // Clear the context  
        onNewMessage('', '', null);  
        setUploadStatus('');  
        setIsUploaded(false);  
        isUploadedFile(false);  
        onClearFile();  
        if (fileInputRef.current) {  
            fileInputRef.current.value = null; // Reset file input value  
        }  
        onClearChat(); // Call the function to clear chat states in Middlebar  
    };  
  
    const handleCloseModal = () => {  
        setIsUploading(false);  
        setUploadStatus('');  
    };  
  
    const handleInputChange = (e) => {  
        setQuery(e.target.value);  
        onChatInputText(e.target.value); // Update chat input text  
    };  
  
    const handleKeyPress = (e) => {  
        if (e.key === 'Enter' && query.trim()) {  
            handleSend();  
        }  
    };  
  
    useImperativeHandle(ref, () => ({  
        send: handleSend, // Expose handleSend  
        sendWithFile: handleSendWithFile, // Expose handleSendWithFile  
        clearInput: () => setQuery(''), // Expose method to clear the chat input  
        getQuery: () => query // Expose method to get the current query  
    }));  
  
    return (  
        <>  
            <div className="flex items-center bg-gray-100 rounded-full px-3 py-2 w-full">  
                <button onClick={handleFileSelection} className="p-2">  
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">  
                        <path strokeLinecap="round" strokeLinejoin="round" d="m18.375 12.739-7.693 7.693a4.5 4.5 0 0 1-6.364-6.364l10.94-10.94A3 3 0 1 1 19.5 7.372L8.552 18.32m.009-.01-.01.01m5.699-9.941-7.81 7.81a1.5 1.5 0 0 0 2.112 2.13" />  
                    </svg>  
                </button>  
                <input  
                    type="file"  
                    ref={fileInputRef}  
                    onChange={handleFileChange}  
                    accept=".pdf"  
                    className="hidden"  
                />  
                <input  
                    type="text"  
                    value={query}  
                    onChange={handleInputChange}  
                    className="flex-grow bg-transparent border-none focus:outline-none focus:ring-0 text-sm mx-2"  
                    placeholder="Ask a question, enter a security issue or refine any responses..."  
                    onKeyPress={handleKeyPress}  
                />  
                <button  
                    onClick={() => query.trim() && handleSend()}  
                    className={`bg-blue-500 hover:bg-blue-600 text-white rounded-full p-2 ${!query.trim() && "opacity-50 cursor-not-allowed"}`}  
                    disabled={!query.trim()}  
                >  
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="size-6">  
                        <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 4.5 21 12m0 0-7.5 7.5M21 12H3" />  
                    </svg>  
                </button>  
                <button  
                    onClick={handleClearChat}  
                    className="bg-blue-500 hover:bg-blue-600 text-white rounded-full p-2 ml-2"  
                >  
                    Clear Chat  
                </button>  
            </div>  
            <UploadModal  
                isUploading={isUploading || uploadStatus !== ''}  
                uploadStatus={uploadStatus}  
                onClose={handleCloseModal}  
            />  
        </>  
    );  
});  
  
export default ChatInput;