import React from 'react'

const Button = ({icon, text, onClick, disabled}) => {
    return (
        <button disabled={disabled} onClick={onClick} className='cursor-pointer flex justify-center p-2 rounded shadow gap-2  items-center bg-white'>
                {icon}
            <p>
                {text}
            </p>
       
        </button>
    )
}

export default Button