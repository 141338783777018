import React, { createContext, useContext, useState, useEffect } from 'react';  
import { useMsal, useIsAuthenticated } from '@azure/msal-react';  
  
const AuthContext = createContext();  
  
export const AuthProvider = ({ children }) => {  
    const { instance, accounts } = useMsal();  
    const isAuthenticated = useIsAuthenticated();  
    const [user, setUser] = useState(null);  
  
    useEffect(() => {  
        if (isAuthenticated && accounts.length > 0) {  
            console.log("User authenticated:", accounts[0]);  
            setUser(accounts[0]);  
        } else {  
            console.log("User not authenticated");  
            setUser(null);  
        }  
    }, [isAuthenticated, accounts]);  
  
    const login = async () => {  
        try {  
            let loginResponse = await instance.loginPopup();  
            console.log("Login successful:", loginResponse.account);  
            setUser(loginResponse.account);  
        } catch (error) {  
            console.error('Login error:', error);  
        }  
    };  
  
    const logout = async () => {  
        try {  
            await instance.logoutPopup();  
            console.log("Logout successful");  
            setUser(null);  
            localStorage.clear(); // Clear local storage on logout  
        } catch (error) {  
            console.error('Logout error:', error);  
        }  
    };  
  
    return (  
        <AuthContext.Provider value={{ user, login, logout }}>  
            {children}  
        </AuthContext.Provider>  
    );  
};  
  
export const useAuth = () => useContext(AuthContext);