import React from 'react';
import {Routes, Route } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import Home from './pages/Home';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App({ instance }) {
  return (

    <>    <MsalProvider instance={instance}>
      <Routes>
        <Route path="/" element={<Home/>} />
        {/* <Route path="/chat" element={<ProtectedRoute>
          <Chat/>
        </ProtectedRoute>} /> */}
      </Routes>
    </MsalProvider>
    <ToastContainer/>
    </>

  );
}

export default App;
