import React, { useState } from 'react';  
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';  
import { AuthProvider, useAuth } from './AuthContext'; // Adjust the import path as necessary  
import "./Home.css";  
import Card from '../components/Card';  
import Chat from './Chat';  
import bg from '../images/bg_new.png';  
import PrivacyPolicyPopup from './PrivacyPolicyPopup'; // Make sure you have this component  
import TermsOfUsePopup from './TermsOfUsePopup'; // Import the new TermsOfUsePopup component  
  
function HomeComponent() {  
    const { login } = useAuth(); // Use the useAuth hook to get the login function  
    const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);  
    const [showTermsOfUse, setShowTermsOfUse] = useState(false); // State for Terms of Use popup  
  
    const handlePrivacyPolicyClick = () => {  
        setShowPrivacyPolicy(true);  
    };  
  
    const handleClosePrivacyPolicy = () => {  
        setShowPrivacyPolicy(false);  
    };  
  
    const handleTermsOfUseClick = () => {  
        setShowTermsOfUse(true);  
    };  
  
    const handleCloseTermsOfUse = () => {  
        setShowTermsOfUse(false);  
    };  
  
    return (  
        <>  
            <AuthenticatedTemplate>  
                <Chat />  
            </AuthenticatedTemplate>  
            <UnauthenticatedTemplate>  
                <div style={{  
                    backgroundImage: `url(${bg})`,  
                    backgroundPosition: 'center',  
                    backgroundSize: "cover",  
                    backgroundRepeat: 'no-repeat',  
                    minHeight: '100vh',  
                    width: '100%',  
                    display: 'flex',  
                    flexDirection: 'column'  
                }}>  
                    <div className="flex flex-col md:flex-row justify-center items-center min-h-screen p-8 md:p-24 gap-5">  
                        <div className='text-wrap flex flex-col gap-5 max-w-3xl'>  
                            <h1 className='text-2xl md:text-3xl font-bold'>  
                                Sign up and log in to easily identify and summarize information and cyber security risks, and develop strategies to address them.  
                            </h1>  
                            <h2 className='text-2xl md:text-3xl font-bold'>  
                                This application is in its research phase, exploring how AI can help with resource shortages and the complexity of managing security risks.  
                            </h2>  
                        </div>  
                        <div className='flex flex-col items-center'>  
                            <Card text={"Create an account or login below"} onClick={login} buttonText={"Login/Sign up"} />  
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: '10px', gap: '10px' }}>  
                                <p style={{ fontSize: '1rem', cursor: 'pointer', textDecoration: 'underline' }} onClick={handlePrivacyPolicyClick}>  
                                    Privacy Policy  
                                </p>  
                                <p style={{ fontSize: '1rem', cursor: 'pointer', textDecoration: 'underline' }} onClick={handleTermsOfUseClick}>  
                                    Terms of Use  
                                </p>  
                            </div>  
                        </div>  
                    </div>  
                </div>  
                {showPrivacyPolicy && <PrivacyPolicyPopup onClose={handleClosePrivacyPolicy} />}  
                {showTermsOfUse && <TermsOfUsePopup onClose={handleCloseTermsOfUse} />}  
            </UnauthenticatedTemplate>  
        </>  
    );  
}  
  
function Home() {  
    return (  
        <AuthProvider>  
            <HomeComponent />  
        </AuthProvider>  
    );  
}  
  
export default Home;