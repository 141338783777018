import React, { useState, useEffect, useRef } from 'react';  
import ChatInput from './Atomic/ChatInput';  
import RightSidebar from './RightSidebar';  
import UpperMIddleBar from './UpperMIddleBar';  
import Loading from './Atomic/Loading';  
import ContentRenderer from './ContentRender';  
  
const Middlebar = ({ userIdMsal, user }) => {  
    const firstNameInitial = user?.idTokenClaims?.given_name?.charAt(0).toUpperCase();  
    const [currentQuery, setCurrentQuery] = useState('');  
    const [currentAnswer, setCurrentAnswer] = useState('');  
    const [prompt, setPrompt] = useState('');  
    const [loading, setLoading] = useState(false);  
    const [filename, setFilename] = useState('');  
    const [chatInputText, setChatInputText] = useState(''); // New state for chatbox text  
    const [promptUpdateCounter, setPromptUpdateCounter] = useState(0);  
    const [context, setContext] = useState(''); // State to hold the conversation context  
  
    const handlePromptClick = (newPrompt) => {  
        setPrompt(newPrompt);  
        setPromptUpdateCounter(prev => prev + 1);  
    };  
  
    useEffect(() => {  
        if (prompt) {  
            if (filename) {  
                chatInputRef.current.sendWithFile(prompt, filename);  
            } else if (chatInputText) {  
                chatInputRef.current.send(chatInputText + " " + prompt);  
            } else {  
                chatInputRef.current.send(prompt);  
            }  
            setChatInputText(''); // Clear the input text after sending  
        }  
    }, [prompt, promptUpdateCounter, filename, chatInputText]);  
  
    const handleNewMessage = (query, answer, filename) => {  
        setCurrentQuery(query);  
        setCurrentAnswer(answer);  
        if (filename) {  
            setFilename(filename);  
        }  
    };  
  
    const handleLoading = (loading) => {  
        setLoading(loading);  
    };  
  
    const handleUploadedFile = (upload) => {  
        // console.log(upload,'inisde')  
    };  
  
    const handleQuery = (query) => {  
        if (query) {  
            setCurrentQuery(query);  
        }  
    };  
  
    const handleFileUpload = (filename) => {  
        setFilename(filename);  
    };  
  
    const handleClearFile = () => {  
        setFilename('');  
    };  
  
    const handleChatInputText = (text) => {  
        setChatInputText(text);  
    };  
  
    const handleClearChat = () => {  
        setCurrentQuery('');  
        setCurrentAnswer('');  
        setContext('');  
        setFilename('');  
        setPrompt('');  
        setChatInputText('');  
        chatInputRef.current.clearInput(); // Clear the chat input  
    };  
  
    const chatInputRef = useRef(); // Create a ref for ChatInput  
  
    return (  
        <div className='flex flex-1 flex-col ml-16 bg-white'>  
            <UpperMIddleBar query={filename} />  
            <div className='flex h-full gap-20'>  
                <div className='w-full h-full flex justify-between flex-col gap-5 pl-10 pb-10'>  
                    <div className='flex flex-col gap-3 pt-5 overflow-y-auto' style={{ maxHeight: 'calc(100vh - 255px)' }}>  
                        {/* Commented out user input display */}  
                        {/*  
                        {currentQuery && (  
                            <div className='flex items-center gap-3'>  
                                <div className="w-10 h-10 self-start text-white rounded-full flex justify-center items-center bg-blue-500">  
                                    <p>{firstNameInitial}</p>  
                                </div>  
                                <div className='bg-[#EEF1F7] font-medium p-2 rounded-md w-full'>  
                                    <p className="rounded-md p-2">{currentQuery}</p>  
                                </div>  
                            </div>  
                        )}  
                        */}  
                        {loading ? <Loading /> : currentAnswer && (  
                            <div className='flex items-center gap-3'>  
                                {/*<div className="w-10 h-10 self-start text-white rounded-full flex justify-center items-center bg-blue-500">  
                                    <p className=''>I</p>  
                                </div>
                                */}  
                                <div className='bg-[#F9FBFC] font-medium p-2 rounded-md w-full'>  
                                    <ContentRenderer content={currentAnswer} />  
                                </div>  
                            </div>  
                        )}  
                    </div>  
                    <div>  
                        <ChatInput  
                            ref={chatInputRef} // Pass the ref to ChatInput  
                            userIdmsal={userIdMsal}  
                            onFileUplaod={handleFileUpload}  
                            onNewMessage={handleNewMessage}  
                            initalQuery={prompt}  
                            initialLoading={handleLoading}  
                            queryData={handleQuery}  
                            isUploadedFile={handleUploadedFile}  
                            onClearFile={handleClearFile} // Pass the clear file handler  
                            onChatInputText={handleChatInputText} // Pass the chat input text handler  
                            context={context} // Pass the context  
                            setContext={setContext} // Pass the setContext method  
                            onClearChat={handleClearChat} // Pass the clear chat handler  
                        />  
                    </div>  
                </div>  
                <div className='w-[40%] h-full bg-[#EEF1F7]'>  
                    <RightSidebar onPromptClick={handlePromptClick} chatInputRef={chatInputRef} />  
                </div>  
            </div>  
        </div>  
    );  
}  
  
export default Middlebar;