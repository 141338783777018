import React, { useState } from 'react';  
import { FaRegDotCircle } from 'react-icons/fa';  
import Logo from '../images/logo.png';  
import Arrow from '../images/arrow.svg';  
import logoutSvg from '../images/logoutsvg.svg';  
import Settings from '../images/settings.svg';  
  
const listItems = [  
  "Enter a question, risk use-case or upload file to analyze",  
  "Currently only .pdf file format is supported for uploads",  
  "Select the relevant purpose you would like to analyze the data with (buttons on the right)",  
  "All data is deleted after 24 hours",  
  "No training is being done on your data (static model in use)",  
  "While data is not retained or trained on, only upload sanitized or non-sensitive data(for your sanity)"  
];  
  
const Timeline = ({ name, icon, logout }) => {  
  const [isMenuVisible, setMenuVisible] = useState(false);  
  
  const toggleMenu = () => {  
    setMenuVisible(!isMenuVisible);  
  };  
  
  return (  
    <div className='pl-8 pt-8 pb-8 flex flex-col justify-between'>  
      <div className='flex flex-col justify-between'>  
        {/* Logo */}  
        <div className='flex items-center pb-10 pt-10'>  
          <img src={Logo} height={100} width={200} alt="Logo" />  
        </div>  
          
        {/* Iterate through listItems and render each item */}  
        <div className='text-sm pt-24'>  
          <h1 className='pb-4 text-white'>NOTICE BOARD</h1>  
          {listItems.map((li, i) => (  
            <div key={i} className="flex gap-x-3">  
              {/* Icon and vertical line */}  
              <div className={`relative ${i < listItems.length - 1 ? 'after:absolute after:top-7 after:bottom-0 after:start-3.5 after:w-px after:bg-gray-200' : ''}`}>  
                <div className="relative z-10 size-7 flex justify-center items-center">  
                  <FaRegDotCircle className="text-[#568EAD] h-[20px] w-[50px]" size={12} />  
                </div>  
              </div>  
                
              {/* Right Content */}  
              <div className="grow pt-0.5 pb-8 w-[200px]">  
                <p className="mt-1 text-sm text-white">  
                  {li}  
                </p>  
              </div>  
            </div>  
          ))}  
        </div>  
      </div>  
  
      {/* User Icon and Name */}  
      <div className='relative flex items-center pt-10 gap-3'>  
        <div className="w-7 h-7 text-white rounded-full flex justify-center items-center bg-blue-500">  
          <p>{icon}</p>  
        </div>  
        <h1 className='text-white'>{name}</h1>  
        <div className='cursor-pointer' onClick={toggleMenu}>  
          <img src={Arrow} alt="Arrow" />  
        </div>  
  
        {/* Dropdown Menu */}  
        {isMenuVisible && (  
          <div className='absolute bottom-5 right-0 mt-2 w-48 bg-white rounded-md shadow-lg py-1 z-10'>  
            <div onClick={toggleMenu} className='flex gap-3 px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100'>  
              <img src={Settings} alt="Settings" />  
              <p>Account Settings</p>  
            </div>  
            <div onClick={() => { logout(); toggleMenu(); }} className='flex gap-3 px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100'>  
              <img src={logoutSvg} alt="Log out" />  
              <p>Log out</p>  
            </div>  
          </div>  
        )}  
      </div>  
    </div>  
  );  
};  
  
const RightSidebar = ({ user, logout }) => {  
  const firstNameInitial = user?.idTokenClaims?.given_name.charAt(0).toUpperCase();  
  return (  
    <Timeline logout={logout} name={`${user?.idTokenClaims?.given_name} ${user?.idTokenClaims?.family_name}`} icon={firstNameInitial} />  
  );  
};  
  
export default RightSidebar;