import React from 'react';


import Success from '../../images/success.svg'
function UploadModal({ isUploading, uploadStatus, onClose }) {
    return (
        <div className={`fixed inset-0 flex items-center justify-center ${!isUploading && 'hidden'}`}>
            <div className="fixed inset-0 bg-white opacity-50"></div>
            <div className="bg-white p-16 rounded-lg shadow-lg z-10  text-center relative">
                {/* Close button */}
                <button
                    onClick={onClose}
                    className="absolute top-2 right-2 text-gray-600 hover:text-gray-900"
                >
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                </button>

                {/* Loader and messages */}
                {uploadStatus === 'Uploading...' && (
                    <>
                        <div className="loader mb-4 flex flex-col justify-center items-center"></div>
                        {/* <Spinner className='h-16 w-16 text-[#2E90D1]/50' color="blue" /> */}
                        <p className='text-[#858585]'>Please wait, Your file is uploading</p>
                        
                    </>
                )}
                {uploadStatus.startsWith('Upload successful') && (
                    <div className='flex flex-col justify-center gap-2 items-center'>
                        <h1 className='text-[#0B0B0B] text-lg font-bold'>Successfully Uploaded</h1>
                        <p className='text-[#6D6D6D]'>Your file is uploaded successfully, now you can chat</p>
                        <img className='pt-4' src={Success} height={70} width={70} alt='success'/>

                    </div>
                )}
                {uploadStatus.startsWith('Error') && (
                    <p className="text-red-600">Error: Could not upload the file. It is either too long or has unsupported formatting.</p>
                )}
            </div>
        </div>
    );
}

export default UploadModal;
