import React from 'react'
import SimpleButton from './Atomic/SimpleButton'

const Card = ({onClick, text, buttonText}) => {
    return (
        <div className='bg-white flex justify-center flex-col items-center gap-5 p-20 rounded'>

            <h1 className='text-[#174666] font-bold text-[40px]'>{text}</h1>
         <div>
         <SimpleButton onClick={onClick}  text={buttonText}/>
         </div>

        </div>
    )
}

export default Card