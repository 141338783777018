import React from 'react';  
import ReactMarkdown from 'react-markdown';  
import remarkGfm from 'remark-gfm';  
import rehypeSanitize from 'rehype-sanitize';  
import './content.css';  
  
const isMarkdown = (text) => {  
  const markdownPattern = /(^#{1,6}\s)|(\*\*[^*]+\*\*)|(\*[^*]+\*)|(__[^_]+__)|(_[^_]+_)|(\[[^\]]+\]\([^)]+\))|(`[^`]+`)|(\s*[*-]\s+)|(^\d+\.\s)|(^\|.*\|$)|(^>\s)|(^---$)|(!\[[^\]]*\]\([^)]+\))|(^```[\s\S]*```)/gm;  
  return markdownPattern.test(text);  
};  
  
const ContentRenderer = ({ content }) => {  
  console.log('Content:', content); // Debugging  
  
  if (isMarkdown(content)) {  
    return (  
      <ReactMarkdown  
        remarkPlugins={[remarkGfm]}  
        rehypePlugins={[rehypeSanitize]}  
        className='reactMarkDown'  
      >  
        {content}  
      </ReactMarkdown>  
    );  
  }  
  return <div className='simple-answer'>{content}</div>;  
};  
  
export default ContentRenderer;  