
import React from 'react'

const Loading = () => {
  return (
    <>
    <div className="loader mb-4 flex flex-col justify-center items-center"></div>
    
</>
  )
}

export default Loading