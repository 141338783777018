import React from 'react';  
import { useAuth } from './AuthContext'; // Adjust the import path as necessary  
import LeftSidebar from '../components/LeftSidebar';  
import Middlebar from '../components/Middlebar';  
import bg2 from '../images/bg_2.png';  
  
const Chat = () => {  
    const { user, logout } = useAuth(); // Use the AuthContext to get the user and logout function  
    const userIdMsal = user?.localAccountId;  
  
    return (  
        <div className="flex flex-col min-h-screen">  
            <div  
                className="flex-grow flex overflow-hidden"  
                style={{  
                    backgroundImage: `url(${bg2})`,  
                    backgroundPosition: 'center',  
                    backgroundSize: "cover",  
                    backgroundRepeat: 'no-repeat'  
                }}  
            >  
                <LeftSidebar logout={logout} user={user} />  
                <Middlebar userIdMsal={userIdMsal} user={user} />  
            </div>  
        </div>  
    );  
};  
  
export default Chat;