import React, { useEffect, useState } from 'react';  
import Button from './Atomic/Button';  
import { MdOutlineFindInPage, MdOutlineCalendarMonth, MdArrowBack, MdArrowForward } from "react-icons/md";  
import { LuCrown } from "react-icons/lu";  
import { DiGitPullRequest } from "react-icons/di";  
import Loading from './Atomic/Loading';  
  
const LeftSidebar = ({ onPromptClick, chatInputRef }) => {  
    const [profiles, setProfiles] = useState({});  
    const [loading, setLoading] = useState(true);  
    const [error, setError] = useState(null);  
    const [selectedProfile, setSelectedProfile] = useState('Risk Management');  
  
    // Icon mapping based on item text  
    const iconMapping = {  
        "Identify Risk": <MdOutlineFindInPage color='blue' size={20} />,  
        "Mitigation Strategies": <LuCrown color='blue' size={20} />,  
        "Remediation Roadmap": <MdOutlineCalendarMonth color='blue' size={20} />,  
        "Compare to ISO27K": <DiGitPullRequest color='blue' size={20} />,  
        "Compare to NIST": <DiGitPullRequest color='blue' size={20} />,  
        "Info Risk": <MdOutlineCalendarMonth color='blue' size={20} />,  
    };  
  
    const profileCopy = {  
        'Risk Management': [  
            "1. Upload any document or assessment.",  
            "2. Click Analyze Risk to find and assess risks, or choose a specific assessment review.",    
            "3. Click Remediation Roadmap to create a plan for the identified solutions.",  
            "4. To create a policy, type a policy topic in the chat bar (don't press enter) and press the Create a Policy button.",  
        ],  
        'Compliance Testing': [  
            "1. Upload various assessments and click the appropriate button.",  
        ],  
        'Security Operations': [  
            "1. Upload reports from your security tools for analysis.",  
            "2. Add vulnerability reports to create summaries and prioritize issues.",  
            "3. Upload incident details and make a simple summary.",  
            "4. Create a plan to fix vulnerabilities or incidents.",  
            "5. Identify recent incidents and understand why they occurred.",  
        ],  
        'Training & Awareness': [  
            "1. Upload a Policy: Upload a InfoSec Policy and click the Policy Training button to create an education plan for that specific policy.",  
            "2. Create an Awareness Plan: Type a security topic in the chat bar (don't press enter) and then click the Create an Awareness Plan button.",  
        ],  
    };  
  
    useEffect(() => {  
        const fetchProfiles = async () => {  
            try {  
                const response = await fetch('/items');  
                if (!response.ok) {  
                    throw new Error('Failed to fetch items');  
                }  
                const data = await response.json();  
                setProfiles(data);  
            } catch (err) {  
                setError(err.message);  
            } finally {  
                setLoading(false);  
            }  
        };  
  
        fetchProfiles();  
    }, []);  
  
    if (loading) {  
        return <Loading />;  
    }  
  
    if (error) {  
        return <div className='p-4 text-red-500'>Error: {error}</div>;  
    }  
  
    const handleButtonClick = async (prompt) => {  
        console.log('Button clicked with prompt:', prompt);  
        if (chatInputRef.current) {  
            const currentQuery = chatInputRef.current.getQuery();  
            if (currentQuery.trim()) {  
                console.log('Sending prompt with user input:', currentQuery);  
                await chatInputRef.current.send(currentQuery + " " + prompt);  
            } else {  
                console.log('Sending prompt without user input:', prompt);  
                await chatInputRef.current.send(prompt);  
            }  
            chatInputRef.current.clearInput(); // Clear the input text after sending  
        }  
    };  
  
    const handleProfileChange = (direction) => {  
        const profileKeys = Object.keys(profiles);  
        const currentIndex = profileKeys.indexOf(selectedProfile);  
        const newIndex = (currentIndex + direction + profileKeys.length) % profileKeys.length;  
        setSelectedProfile(profileKeys[newIndex]);  
    };  
  
    return (  
        <div className='p-4 flex flex-col gap-3'>  
            <div className="flex justify-center items-center mb-4">  
                <button  
                    onClick={() => handleProfileChange(-1)}  
                    className="bg-blue-500 hover:bg-blue-600 text-white rounded-full p-2"  
                >  
                    <MdArrowBack size={24} />  
                </button>  
                <div className="mx-4 text-lg font-bold text-xl">  
                    {selectedProfile ? selectedProfile : 'Select a Profile'}  
                </div>  
                <button  
                    onClick={() => handleProfileChange(1)}  
                    className="bg-blue-500 hover:bg-blue-600 text-white rounded-full p-2"  
                >  
                    <MdArrowForward size={24} />  
                </button>  
            </div>  
  
            {selectedProfile && profiles[selectedProfile].map((item, index) => (  
                <Button  
                    key={index}  
                    text={item.text}  
                    icon={iconMapping[item.text] || <MdOutlineFindInPage color='blue' size={20} />}  
                    onClick={() => handleButtonClick(item.variable)}  
                />  
            ))}  
  
            <div className='flex flex-col gap-7 mt-4'>  
                <h1 className='font-bold'>Suggested Workflows</h1>  
                <div className='flex flex-col gap-2'>  
                    {profileCopy[selectedProfile].map((item, index) => (  
                        <div key={index}>  
                            <p>{item}</p>  
                        </div>  
                    ))}  
                </div>  
                <div>For any questions, suggestions or feedback, please reach out to contact@infosecrisk.ai</div>  
            </div>  
        </div>  
    );  
};  
  
export default LeftSidebar;